<template>
	<div :class="themeClass" class="contBox margin-t-10 bg-fff">
		<div class="newPersonbtn-Boxx">
			<div class="newPersonbtn-title font-size18 font-weight700">我的资料</div>
		</div>
		<div class="newPersonbtn-Box">
			<el-form :model="formData" :rules="rules" ref="formData" label-width="130px" class="demo-ruleForm" v-loading='formLoading' label-position="left" >
				<div class="text-center font-size16 margin-b-25 flex-row-center-center" style="width: 520px;">
					<span class="iconfont icon-biaodan xinzengIcon color-theme font-size25"></span>
					实名认证
				</div>
				<el-form-item v-for="(item, index) in uploadRule" :key="index" :label="item.rule.fRuleName">
					<div class="padding-10 border-F2F2F2">
						<UploadImgList :rules="item.rule" :uploadToken="uploadToken[index]" class="uploadimglist" :limit="1" :accept="limitTypeArr[index]"
						 v-model="imgListName[index]" uploadType="image" @success="uploadSuccess" />
					</div>
					<div class="font-size12 font-color-FF9900 margin-t-4" style="line-height: normal;" v-if="limitTypeArr[index]||item.rule.fMaxSize||item.rule.fMaxWidth||item.rule.fMaxHeight">
						* 提示：
						<span>格式为{{item.rule.fFileType}}</span>
						<span v-if="item.rule.fMaxSize">,大小不能超过{{ item.rule.fMaxSize }}MB</span>
						<!-- <span v-if="item.rule.fMaxWidth">,宽度不能超过{{ item.rule.fMaxWidth }}px</span>
						<span v-if="item.rule.fMaxHeight">,高度不能超过{{ item.rule.fMaxHeight }}px</span> -->
					</div>
				</el-form-item>
				<el-form-item  label="姓名" prop="realName">
					<el-input v-model="formData.realName" placeholder="请先上传身份证照片，系统将自动为您识别填入" :disabled="!isEditOne||!isUpload"></el-input>
				</el-form-item>
				<el-form-item label="身份证号码" prop="IDCardNumber">
					<el-input v-model="formData.IDCardNumber" placeholder="请先上传身份证照片，系统将自动为您识别填入" :disabled="!isEditOne||!isUpload">
					</el-input>
				</el-form-item>
				<el-form-item class="phoneRed" label="手机号">
					<el-input :value="getUserInfo.phone" disabled></el-input>
				</el-form-item>
				<el-form-item label="业务服务区域" class="areaBox" prop="fGovernmenAreaID">
					<selectArea v-if="hasGovernmenArea" v-model="formData.fGovernmenAreaID"></selectArea>
					<selectArea v-if="!hasGovernmenArea&&formData.fGovernmenAreaID.length>0" v-model="formData.fGovernmenAreaID"></selectArea>
					<div class="font-size12 font-color-FF9900 margin-t-4" style="line-height: normal;">
						* 提示：平台将根据您所选择的业务服务区域，为您推送相关区域内的公告信息
					</div>
				</el-form-item>
				<el-form-item class="yaoQred" label="邀请码">
					<el-input v-model="formData.inviterCode" placeholder="请输入邀请码，若无人邀请可不用填写" :disabled="!isEditOne"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button class="color-theme border-theme" @click="submitInform(1)" :loading="buttonLoadingTJ">{{buttonText}}</el-button>
					<el-button class="color-theme border-theme" @click="submitInform(2)" :loading="buttonLoadingZC">暂存</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="bg-box"></div>
	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex';
	import selectArea from '@/components/selectArea/selectArea.vue'
	import UploadImgList from '@/components/upload/UploadImgList';
	import Verification from '@/utils/Verification';
	import IdentityCodeValid from '@/utils/checkIdent';
	export default {
		name: "SelectArea",
		components: {
			selectArea,
			UploadImgList
		},
		data() {
			const validatePhoneNumber = (rule, value, callback) => {
				if (!Verification.Verification.IsPhoneNumber(value)) {
					callback(new Error(Verification.Verification.PhoneNumberInfo));
				} else {
					callback();
				}
			};
			const governmenArea = (rule, value, callback) => {
				if (!value[0]) {
					callback(new Error('请选择业务服务区域'));
				} else {
					callback();
				}
			};
			let validateIDCard = (rule, value, callback) => {
				if (!IdentityCodeValid(value)) {
					callback(new Error('请输入正确的身份证号'));
				} else {
					callback();
				}
			};
			return {
				getYZ: "获取验证码",
				miao: '',
				formData: {
					realName: '', //姓名
					IDCardNumber: '', //身份证号
					fGovernmenAreaID: [], //选择行政区域ID
					fRemark: '' //备注
				},
				rules: {
					realName: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					IDCardNumber: [{
						required: true,
						message: '请输入身份证号',
						trigger: 'blur'
					}, {
						validator: validateIDCard,
						trigger: "blur"
					}],
					fGovernmenAreaID: [{
						required: true,
						validator: governmenArea,
						trigger: "change"
					}],
				},
				businessList: [], //行业性质
				uploadRule: [], //上传图片规则
				uploadToken: [], //上传图片token
				limitTypeArr: [], //上传图片格式规则
				imgListName: [], //上传图片名
				// action: 'http://192.168.201.34:51045/api/henghe/blob/upload', //上传图片路径
				action:'https://www.henghesoft.com:8444/api/henghe/blob/upload', //上传图片路径
				isEdit: true, //是否可以编辑
				backNum: -2,
				userData: {},
				buttonText: '提交审核',
				isEditOne: true, //编辑能否修改
				isUpload: false, //身份证是否上传
				isCommit: false, //是否立即提交
				formLoading: false, //表单加载
				hasGovernmenArea: false, //显示行政区域
				buttonLoadingZC: false, //暂存按钮加载
				buttonLoadingTJ: false, //提交按钮加载
			}
		},
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			this.getAdjustBillTypeAnnexRule();
			this.getUserCurrentBill();
		},
		methods: {
			//获取用户完善资料
			getUserCurrentBill() {
				this.formLoading = true;
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-current-bill')
					.then(
						res => {
							// console.log('用户资料', res);
							if (res.obj) {
								this.showGovernmenArea = false;
								this.userData = res.obj;
								if (this.userData.fCommerceTypeID == 1) {
									this.isUpload = true;
									if (this.userData.fStatus == 0 && this.userData.fApproveStatus == 1) {
										this.isEdit = false
										this.buttonText = this.userData.fApproveStatusName;
									}
									if (this.userData.fStatus == 1 && this.userData.fApproveStatus == 254) {
										this.buttonText = '变更资料';
									}
									if (this.userData.fStatus == 1) {
										this.isEditOne = false;
									}
									this.formData.realName = this.userData.fCustomerName; //姓名
									this.formData.IDCardNumber = this.userData.fIdentityCode; //身份证号
									let _this = this
									this.uploadRule.forEach((item, index) => {
										_this.userData.files.forEach((item1, index1) => {
											if (item.rule.fRuleID == item1.fRuleID) {
												_this.imgListName[index] = [];
												_this.imgListName[index].push({
													fPath: item1.fPath,
													ruleId: item1.fRuleID
												})
											}
										});
									});
									//行政区域数据处理
									if (this.userData.fFullIDPath != '') {
										let governmenAreaArr = this.userData.fFullIDPath.split("\\");
										// console.log(governmenAreaArr);
										//过滤数组中的空字符
										governmenAreaArr = governmenAreaArr.filter((s) => {
											return s && s.trim();
										});
										this.formData.fGovernmenAreaID = governmenAreaArr
									} else {
										this.hasGovernmenArea = true;
									}
									this.formData.inviterCode = this.userData.fInvitationCode; //邀请码
									this.backNum = -2;
									// console.log(this.formData);
								} else {
									this.hasGovernmenArea = true;
								}
							} else {
								this.hasGovernmenArea = true;
							}
							this.formLoading = false;
						},
						error => {}
					);
			},
			//获取验证规则
			getAdjustBillTypeAnnexRule() {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-adjust-bill-type-annex-rule')
					.then(res => {
						console.log('获取附件验证规则', res);
						this.uploadRule = res;
						let Token = {};
						let Type = [];
						res.forEach((item, index) => {
							Type[index] = item.rule.fFileType.split(',');
							Token.token = item.token;
							this.uploadToken.push(Token);
						});
						Type.forEach(item => {
							let imitTypeStr = ''
							item.forEach(item1 => {
								imitTypeStr = imitTypeStr + '.' + item1 + ','
							})
							this.limitTypeArr.push(imitTypeStr)
						})
					}, error => {

					});
			},
			//上传成功识别身份证
			uploadSuccess(e, url) {
				// console.log('上传成功', e, url);
				let param = {
					imageUrl: url + e[0].fPath
				}
				this.ApiRequestPost('api/mall/tencent-cloud/ocr-id-card-by-image-url', param)
					.then(res => {
						// console.log(res);
						if (res.isSucceed) {
							this.formData.realName = res.name
							this.formData.IDCardNumber = res.idNum
						} else {
							this.$message('身份证识别失败，请手动输入');
						}
						this.isUpload = true;
					}, error => {
						this.isUpload = true;
					});
			},
			//表单验证
			submitInform(e) {
				let imageList = this.imgListName;
				for (let item of this.uploadRule) {
					if (item.rule.fIsNecessary == 1) {
						let flag = {}
						for (let item1 of imageList) {
							if (item1 && item1 != []) {
								flag = item1.find(findItem => findItem.ruleId == item.rule.fRuleID);
								if (flag) {
									break;
								}
							}
						}
						flag = flag ? flag : {};
						if (!flag.ruleId) {
							this.$message({
								message: item.rule.fRuleName + '不能为空',
							});
							return
						}
					}
				}
				if (e == 2) {
					let flag1 = true;
					this.$refs.formData.validateField("IDCardNumber", (message) => {
						if (message != '') {
							flag1 = false;
						}
					});
					if (flag1) {
						this.Auditing(e);
					} else {
						content.scrollIntoView()
					}
				} else {
					this.$refs.formData.validate((valid) => {
						if (valid) {
							this.Auditing(e);
						} else {
							return false;
						}
					});
				}
			},
			//提交审核
			Auditing(type) {
				if (type == 1) {
					//审核
					this.isCommit = true;
					this.buttonLoadingTJ = true;
				} else {
					//保存
					this.isCommit = false;
					this.buttonLoadingZC = true;
				}
				let param = {
					files: [], //图片参数
					fCustomerName: '',
					fGovernmenAreaID: "",
					fIdentityCode: '',
					fPhone: '',
					fRemark: '',
					fAppTypeId: this.fAppTypeID,
					inviterCode: '',
					isCommit: this.isCommit,
				};
				for (let item of this.imgListName) {
					for (let items of item) {
						if (items && items != []) {
							let imgData = {
								fFileName: items.fSourceFileName ? items.fSourceFileName : items.fPath,
								fPath: items.fPath,
								ruleId: items.ruleId
							}
							param.files.push(imgData)
						}
					}
				}
				param.fCustomerName = this.formData.realName;
				param.fGovernmenAreaID = this.formData.fGovernmenAreaID[this.formData.fGovernmenAreaID.length - 1];
				param.fGovernmenAreaID = param.fGovernmenAreaID ? param.fGovernmenAreaID : -1
				param.fIdentityCode = this.formData.IDCardNumber;
				param.fPhone = this.getUserInfo.phone;
				param.inviterCode = this.formData.inviterCode ? this.formData.inviterCode : '';
				param.fRemark = this.formData.fRemark;
				console.log(param);
				this.ApiRequestPost('api/mall/ebcustomer/adjust-bill/create-or-update', param)
					.then(res => {
						let _this = this;
						if (this.isCommit) {
							// setTimeout(() => {
							// 	_this.$router.replace({
							// 		path: "/PersonalContent/myStuff",
							// 	});
							// }, 2000);
							setTimeout(() => {
								_this.$router.replace({
									path: "/UnderReview",
									query: {
										type: 1
									}
								});
							}, 2000);
						} else {
							// setTimeout(() => {
							// 	_this.$router.replace({
							// 		path: "/PersonalContent/Allperfect",
							// 	});
							// }, 2000);
						}
						this.buttonLoadingTJ = false;
						this.buttonLoadingZC = false;
					}, error => {
						this.buttonLoadingTJ = false;
						this.buttonLoadingZC = false;
					});
			},

		}
	}
</script>

<style lang="scss" scoped="scoped">
	/deep/ .el-form-item__label:before{
		content: '*';
		color: #F56C6C;
		margin-right: 4px;
		opacity: 0;
	}
	/deep/ .el-form-item__label:after{
		content: '*';
		color: #F56C6C;
		margin-left: 4px;
	}
	
	.phoneRed /deep/ .el-form-item__label:after{
		content: '*';
		color: #F56C6C;
		margin-left: 4px;
		opacity: 0;
	}
	
	.yaoQred /deep/ .el-form-item__label:after{
		content: '*';
		color: #F56C6C;
		margin-left: 4px;
		opacity: 0;
	}
	.areaBox /deep/ .el-form-item__label:after{
		content: '*';
		color: #F56C6C;
		margin-left: 4px;
		// opacity: 0;
	}
	
	
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}


	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.PersonanProfile-Number {
		font-size: 12px;
		color: #0173fe;
	}

	.upload-box {
		width: 331px;
		height: 117px;
		border-radius: 4px;
		border: 1px solid rgba(228, 228, 228, 1);
	}

	.contBox {
		width: 100%;
	}

	.demo-ruleForm {
		width: 462px;
		margin: 0px auto;
		margin-left: 200px;
		position: relative;
		z-index: 1;
	}

	.newPersonbtn-title1 {
		width: 110px;
		height: 30px;
		background-color: #0173FE;
		border-radius: 2px;
		color: #FFFFFF;
		line-height: 30px;
		text-align: center;
		// float:right;
	}

	.newPersonbtn-Boxx {
		display: flex;
		justify-content: space-between;
		margin-right: 20px;
	}

	::v-deep .el-upload--picture-card {
		width: 97px;
		height: 97px;
		line-height: 97px;
		margin: 10px 0px 0px 10px;
	}

	::v-deep .el-upload-list--picture-card .el-upload-list__item {
		width: 97px;
		height: 97px;
		margin: 10px 0px 0px 10px;
	}

	.newPersonbtn-Box {}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.newPersonbtn-title {
		width: 73px;
		height: 22px;
		color: #000000;
		padding: 8px 13px;
	}

	.el-button {
		width: 160px;
		height: 40px;
	}

	::v-deep .el-input__inner,
	.el-select {
		width: 100%;
		height: 32px;
		line-height: 32px;
	}

	.areaBox {
		position: relative;
		
	}

	.areaBox::v-deep .el-cascader {
		width: 100%;
	}

	.rule-name::v-deep .el-form-item__label {
		line-height: normal;
	}

	// .uploadimglist {
	// 	border: 1px solid #d0ebee;
	// 	border-radius: 4px;
	// }
</style>
